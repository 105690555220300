import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: disc;

  && {
    padding: 0 30px;
    margin-bottom: 18px;
  }
`;

export const ListItem = styled.li``;
