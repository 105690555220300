import { useEffect, useRef, useState, useCallback } from 'react';

export const useTimer = (initialTime = 0) => {
  const [time, setTime] = useState(initialTime);
  const timerIdRef = useRef(null);
  const [shouldStartTimer, setShouldStartTimer] = useState(false);

  const decrementTimer = () => {
    setTime((prevTime) => {
      const newTime = prevTime - 1;

      if (newTime >= 0) {
        return newTime;
      }

      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }

      setShouldStartTimer(false);
      return 0;
    });
  };

  useEffect(() => {
    if (shouldStartTimer) {
      timerIdRef.current = setInterval(decrementTimer, 1000);
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [shouldStartTimer]);

  const startTimer = useCallback((newTime) => {
    setTime(newTime);
    setShouldStartTimer(true);
  }, []);

  return { time, startTimer };
};
