import React, { ReactNode } from 'react';
import { GenericText, Paragraph } from './styles';

export enum TextTypes {
  generic,
  paragraph
}

type TextPropsType = {
  children: ReactNode;
  type?: TextTypes.generic | TextTypes.paragraph;
};

export const Text = ({ children, type }: TextPropsType) => {
  if (type === TextTypes.paragraph) {
    return (
      <Paragraph
        data-testid="text-paragraph"
        className="label"
      >
        {children}
      </Paragraph>
    );
  }

  return (
    <GenericText
      data-testid="text-generic"
      className="label"
    >
      {children}
    </GenericText>
  );
};
