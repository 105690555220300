import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client';
import { interfaces } from '@jarvis/shell-commons';
import { Stack } from '../types/shell';

export async function userTenantApiCall({
  authProvider,
  stack
}: {
  authProvider: typeof interfaces.v1.authProvider;
  stack: Stack;
}) {
  try {
    const client = new UserTenantMgtSvcClient(stack, authProvider);
    const response = await client.getCurrentActiveUserWithTenant();
    return response.data;
  } catch (e) {
    console.error(e);
    return {};
  }
}
