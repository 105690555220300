import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Content = styled.div`
  padding: ${({ theme }) => `${theme.orbitalThree} ${theme.orbitalSix} ${theme.orbitalFour} ${theme.orbitalSix}`};
`;

export const Separator = styled.div`
  border-bottom: ${Tokens.colorGray3} 1px solid;
`;
