import { interfaces } from '@jarvis/shell-commons';
import {
  JWEB_MAX_THRESHOLD_TIME,
  JWEB_THRESHOLD_TIME
} from '../constants/criticalScopes';
import { isJWebNative } from './jweb/auth';
import { getDateDifferenceInSeconds } from './utils';

export const decodeAccessToken = (token: string) => {
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    return null;
  }
};

export const getTenantId = ({
  tenantHandlerInterface
}: {
  tenantHandlerInterface:
    | typeof interfaces.v1.tenantHandlerInterface
    | { getTenantId: () => string };
}): string => {
  try {
    return tenantHandlerInterface.getTenantId();
  } catch (e) {
    return '';
  }
};

// The below code is from the following file: https://github.azc.ext.hp.com/ClientOS/commons-northbound-api/blob/master/src/services/session/CriticalScopeHandler.ts
// It was necessary to be copied because for now they aren't deployed

export const isCriticalScopesValid = async ({
  authToken
}: {
  authToken: typeof interfaces.v1.authToken;
}): Promise<boolean> => {
  const accessToken = await authToken.getToken();
  const decodedAccessToken = decodeAccessToken(accessToken.token);
  const caExpDate = new Date(decodedAccessToken?.ca_exp * 1000);
  return caExpDate > new Date();
};

export const getLoginCooldown = async ({
  authToken
}: {
  authToken: typeof interfaces.v1.authToken;
}) => {
  if (isJWebNative()) {
    const accessToken = await authToken.getToken();
    const decodedAccessToken = decodeAccessToken(accessToken.token);
    const tokenCreationDate = new Date(decodedAccessToken.iat * 1000);

    return Math.min(
      JWEB_MAX_THRESHOLD_TIME,
      Math.max(
        0,
        JWEB_THRESHOLD_TIME -
          getDateDifferenceInSeconds(new Date(), tokenCreationDate)
      )
    );
  }

  return 0;
};

export const ensureCriticalScopes = async ({
  waitCallback = () => {},
  authToken,
  authProvider
}): Promise<boolean> => {
  if (await isCriticalScopesValid({ authToken })) {
    return true;
  }

  if (!isJWebNative()) {
    // Web Version
    await authProvider.forceLogin();
    return true;
  }

  // Native Version
  const threshold = await getLoginCooldown({ authToken });

  if (threshold <= 0) {
    await authProvider.forceLogin();
    return true;
  } else {
    waitCallback();
    return false;
  }
};
