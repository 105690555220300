import React, { useMemo } from 'react';
import { DeleteAccountTemplate } from '../../components/DeleteAccountTemplate';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import Button from '@veneer/core/dist/scripts/button';
import { Text, TextTypes } from '../../components/Text';
import { replaceWithJsx } from '../../utils/utils';
import { Link } from '../../components/Link';
import { UseRootContextType } from '../../types/AppTypes';
import { DEEPLINKS } from '../../constants/deeplinks';
import { SHELL_STATES } from 'src/constants/shellStateParam';
import { DELETE_ACCOUNT_API_ERRORS } from '../../constants/deleteAccountApiErrors';
import {
  closeServiceInstance,
  sendJWebFinishEvent
} from '../../utils/jweb/eventService';
import {
  TechnicalIssueCloseButtonClicked,
  TechnicalIssueHpSupportLinkClicked,
  TechnicalIssueRetryButtonClicked,
  TechnicalIssueScreenDisplayed,
  UnableToDeleteOpenDashboardButtonClicked,
  UnableToDeleteScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';

type DeleteAccountErrorPropsType = {
  errorCode: string;
  deleteAccount: () => void;
};

export const DeleteAccountError = ({
  errorCode,
  deleteAccount
}: DeleteAccountErrorPropsType) => {
  const { t } = useI18n();

  const {
    shell: { navigation }
  }: UseRootContextType = useRootContext();

  const subscriptionExistsError = useMemo(
    () => ({
      title: t(
        'myAccount.profile.accountDeletionPage.modalError.headerCancelServices'
      ),
      description: t(
        'myAccount.profile.accountDeletionPage.modalError.errors.subscriptionExists'
      ),
      buttons: (
        <Button
          data-testid="delete-account-error-open-dashboard-button"
          appearance="primary"
          onClick={() => {
            publishEvent({
              ...UnableToDeleteOpenDashboardButtonClicked,
              screenName: 'UnableToDeleteCancelServices'
            });
            navigation.push(
              `${DEEPLINKS.PRINT_PLANS}?shellState=${SHELL_STATES.nonGateway}`
            );
          }}
        >
          {t(
            'myAccount.profile.accountDeletionPage.modalError.errors.openDashboard'
          )}
        </Button>
      ),
      screenDisplayedEvent: () => {
        publishEvent({
          ...UnableToDeleteScreenDisplayed,
          screenName: 'UnableToDeleteCancelServices'
        });
      },
      dataTestId: 'delete-account-error-subscription-exists'
    }),
    [navigation, t]
  );

  const deviceWithActiveSharesError = useMemo(
    () => ({
      title: t('myAccount.profile.accountDeletionPage.modalError.header'),
      description: t(
        'myAccount.profile.accountDeletionPage.modalError.errors.sharedUsersExists'
      ),
      buttons: (
        <Button
          data-testid="delete-account-error-open-dashboard-button"
          appearance="primary"
          onClick={() => {
            publishEvent({
              ...UnableToDeleteOpenDashboardButtonClicked,
              screenName: 'UnableToDeleteSharedPrinter'
            });
            navigation.push(
              `${DEEPLINKS.ACCOUNT_DETAILS_MANAGE_USERS}?shellState=${SHELL_STATES.nonGateway}`
            );
          }}
        >
          {t(
            'myAccount.profile.accountDeletionPage.modalError.errors.openDashboard'
          )}
        </Button>
      ),
      screenDisplayedEvent: () => {
        publishEvent({
          ...UnableToDeleteScreenDisplayed,
          screenName: 'UnableToDeleteSharedPrinter'
        });
      },
      dataTestId: 'delete-account-error-shared-with-active-shares'
    }),
    [navigation, t]
  );

  const noPermissionError = useMemo(
    () => ({
      title: t('myAccount.profile.accountDeletionPage.modalError.header'),
      description: t(
        'myAccount.profile.accountDeletionPage.modalError.errors.contactAccountAdministrator'
      ),
      buttons: (
        <Button
          data-testid="delete-account-error-ok-button"
          appearance="primary"
          onClick={async () => {
            publishEvent({
              ...UnableToDeleteOpenDashboardButtonClicked,
              screenName: 'UnableToDeleteContactAdmin',
              controlName: 'Ok'
            });
            await sendJWebFinishEvent();
            await closeServiceInstance();
          }}
        >
          {t('myAccount.profile.accountDeletionPage.modalError.okButton')}
        </Button>
      ),
      screenDisplayedEvent: () => {
        publishEvent({
          ...UnableToDeleteScreenDisplayed,
          screenName: 'UnableToDeleteContactAdmin'
        });
      },
      dataTestId: 'delete-account-error-no-permission'
    }),
    [t]
  );

  const defaultError = useMemo(
    () => ({
      title: t('myAccount.profile.accountDeletionPage.modalError.header'),
      description: replaceWithJsx(
        t('myAccount.profile.accountDeletionPage.modalError.errors.default'),
        [
          {
            start: '{{tagReplaceStart}}',
            end: '{{tagReplaceEnd}}',
            jsx: (text: string) => <strong>{text}</strong>
          },
          {
            placeholder: '{{link}}',
            jsx: () => (
              <Link
                href="https://support.hp.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => publishEvent(TechnicalIssueHpSupportLinkClicked)}
              >
                {t(
                  'myAccount.profile.accountDeletionPage.modalError.errors.defaultLink'
                )}
              </Link>
            )
          }
        ]
      ),
      buttons: (
        <React.Fragment>
          <Button
            appearance="primary"
            onClick={() => {
              publishEvent(TechnicalIssueRetryButtonClicked);
              deleteAccount();
            }}
          >
            {t('myAccount.profile.accountDeletionPage.modalError.retryButton')}
          </Button>
          <Button
            data-testid="delete-account-error-close-button"
            appearance="ghost"
            onClick={async () => {
              publishEvent(TechnicalIssueCloseButtonClicked);
              await sendJWebFinishEvent();
              await closeServiceInstance();
            }}
          >
            {t('myAccount.profile.accountDeletionPage.modalError.closeButton')}
          </Button>
        </React.Fragment>
      ),
      screenDisplayedEvent: () => {
        publishEvent(TechnicalIssueScreenDisplayed);
      },
      dataTestId: 'delete-account-error-default'
    }),
    [deleteAccount, t]
  );

  const { title, description, buttons, screenDisplayedEvent, dataTestId } =
    useMemo(() => {
      switch (errorCode) {
        case DELETE_ACCOUNT_API_ERRORS.SubscriptionExists:
          return subscriptionExistsError;
        case DELETE_ACCOUNT_API_ERRORS.DeviceWithActiveShares:
          return deviceWithActiveSharesError;
        case DELETE_ACCOUNT_API_ERRORS.Forbidden:
          return noPermissionError;
        default:
          return defaultError;
      }
    }, [
      defaultError,
      deviceWithActiveSharesError,
      errorCode,
      noPermissionError,
      subscriptionExistsError
    ]);

  return (
    <DeleteAccountTemplate
      title={{ message: title, iconType: 'warning' }}
      buttons={buttons}
      screenDisplayedEvent={screenDisplayedEvent}
      data-testid={dataTestId}
    >
      <Text type={TextTypes.paragraph}>{description}</Text>
    </DeleteAccountTemplate>
  );
};
