import React from 'react';
import PropTypes from 'prop-types';
import HeaderArea from '../HeaderArea';
import { Container, LeftArea, Title } from './styles';

const CardHeader = ({
  className,
  icon,
  title,
  actionArea,
}) => (
  <Container className={className}>
    <HeaderArea
      leftArea={(
        <LeftArea>
          {icon}
          <Title className="subtitle-regular">{title}</Title>
        </LeftArea>
      )}
      rightArea={actionArea}
    />
  </Container>
);

CardHeader.defaultProps = {
  className: null,
  icon: null,
  actionArea: null,
};

CardHeader.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  actionArea: PropTypes.node,
};

export default CardHeader;
