import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';
import { interfaces } from '@jarvis/shell-commons';
import { Stack } from '../types/shell';

export async function deleteAccountApiCall({
  authProvider,
  stack,
  tenantId
}: {
  authProvider: typeof interfaces.v1.authProvider;
  stack: Stack;
  tenantId: string;
}) {
  try {
    const client = new AccountMgtSvcClient(stack, authProvider);
    return await client.deleteAccount(tenantId);
  } catch (error) {
    return error?.response;
  }
}
