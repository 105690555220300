import React from 'react';
import { DeleteAccount } from 'src';
import { ShellRootComponentProps } from 'src/types/shell';
import projectNames from './configs/projectNames';
import resources from '../src/assets/locale';
import { RootProvider } from '@jarvis/react-portal-addons';
import '../src/styles/global.scss';

export default function Root({ ...props }: ShellRootComponentProps) {
  const { stack } = props;
  const { v1 } = window.Shell;

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <RootProvider
        localization={v1.localization}
        resources={resources}
        shell={v1}
        authProvider={v1.authProvider}
        stack={stack}
      >
        <DeleteAccount />
      </RootProvider>
    </section>
  );
}
