import { useCallback, useEffect, useState } from 'react';

export default function useApiCall({
  apiCall,
  fetch,
  initialData,
  initialFetch = true,
  init = true,
  params,
}) {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(init && initialFetch);
  const [success, setSuccess] = useState(null);

  const makeApiCall = useCallback(async opts => {
    try {
      setPending(true);
      setError(null);
      setSuccess(null);

      const output = apiCall ? await apiCall(opts || params) : await fetch();

      setData(output);
      setSuccess(true);
    } catch (err) {
      setError(err);
      setSuccess(false);
      console.error(err);
    } finally {
      setPending(false);
    }
  }, [apiCall, fetch, params]);

  useEffect(() => {
    if (init && initialFetch) {
      makeApiCall();
    }
  }, [initialFetch, init, makeApiCall]);

  return {
    data,
    error,
    forceFetch: makeApiCall,
    isFetching: pending,
    makeApiCall,
    pending,
    success,
  };
}
