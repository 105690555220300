import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const HeaderArea = ({
  className,
  leftArea,
  centerArea,
  rightArea,
}) => (
  <Container className={className}>
    {leftArea}
    {centerArea}
    {rightArea}
  </Container>
);

HeaderArea.defaultProps = {
  className: null,
  leftArea: null,
  centerArea: null,
  rightArea: null,
};

HeaderArea.propTypes = {
  className: PropTypes.string,
  leftArea: PropTypes.node,
  centerArea: PropTypes.node,
  rightArea: PropTypes.node,
};

export default HeaderArea;
