import React, { useState, useEffect, useCallback } from 'react';
import { useRootContext, useI18n } from '@jarvis/react-portal-addons';
import { deleteAccountApiCall } from '../api/deleteAccountApiCall';
import { userTenantApiCall } from '../api/userTenantApiCall';
import { UseRootContextType } from '../types/AppTypes';
import { DELETE_ACCOUNT_API_ERRORS } from '../constants/deleteAccountApiErrors';
import { SUCCESS_HTTP_CODES } from '../constants/httpStatusCodes';
import { ROLE_CATEGORIES } from '../constants/roleCategories';
import { WaitModal } from '../components/WaitModal';
import {
  getTenantId,
  ensureCriticalScopes,
  isCriticalScopesValid
} from '../utils/auth';
import {
  DeleteAccountError,
  DeleteAccountSuccess,
  DeleteAccountLoading,
  DeleteAccountHome
} from '../screens';

enum DeletionStatus {
  checkingPermission,
  home,
  inProgress,
  success,
  error
}

type DeletionStateType = {
  status: DeletionStatus;
  errorCode?: string;
};

export const DeleteAccount = () => {
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [deletionState, setDeletionState] = useState<DeletionStateType>({
    status: DeletionStatus.checkingPermission
  });

  const {
    shell: { tenantHandlerInterface, authProvider, authToken },
    stack
  }: UseRootContextType = useRootContext();

  const { t } = useI18n();

  const callEnsureCriticalScopes = useCallback(async (): Promise<boolean> => {
    return await ensureCriticalScopes({
      authProvider,
      authToken,
      waitCallback: () => {
        setShowWaitModal(true);
      }
    });
  }, [authProvider, authToken]);

  const deleteAccount = useCallback(async () => {
    setDeletionState({ status: DeletionStatus.inProgress });

    const tokenHasCriticalScopes = await isCriticalScopesValid({ authToken });

    if (tokenHasCriticalScopes) {
      const tenantId = getTenantId({ tenantHandlerInterface });

      if (tenantId) {
        const response = await deleteAccountApiCall({
          tenantId,
          authProvider,
          stack
        });

        if (SUCCESS_HTTP_CODES.includes(response?.status)) {
          setDeletionState({ status: DeletionStatus.success });
        } else {
          setDeletionState({
            status: DeletionStatus.error,
            errorCode: `${response?.data?.errors?.[0]?.code}`
          });
        }
      } else {
        setDeletionState({
          status: DeletionStatus.error,
          errorCode: DELETE_ACCOUNT_API_ERRORS.Default
        });
      }
    } else {
      await callEnsureCriticalScopes();
    }
  }, [
    authProvider,
    authToken,
    callEnsureCriticalScopes,
    stack,
    tenantHandlerInterface
  ]);

  useEffect(() => {
    const checkPermissionToDeleteAccount = async () => {
      const userTenant = await userTenantApiCall({ authProvider, stack });
      const roleCategory = userTenant?.userTenantDetail?.roleCategory;

      // If the roleCategory is not present because an API error OR the roleCategory is 'Admin', the user can TRY to delete the account.
      const hasPermissionToDeleteAccount =
        !roleCategory || roleCategory === ROLE_CATEGORIES.ADMIN;

      if (hasPermissionToDeleteAccount) {
        setDeletionState({
          status: DeletionStatus.home
        });

        return;
      }

      setDeletionState({
        status: DeletionStatus.error,
        errorCode: DELETE_ACCOUNT_API_ERRORS.Forbidden
      });
    };

    if (deletionState.status === DeletionStatus.checkingPermission) {
      checkPermissionToDeleteAccount();
    }
  }, [authProvider, deletionState.status, stack]);

  return (
    <React.Fragment>
      {deletionState.status === DeletionStatus.checkingPermission && (
        <DeleteAccountLoading />
      )}
      {deletionState.status === DeletionStatus.home && (
        <DeleteAccountHome
          deleteAccount={deleteAccount}
          authToken={authToken}
        />
      )}
      {deletionState.status === DeletionStatus.inProgress && (
        <DeleteAccountLoading
          message={t('myAccount.profile.accountDeletionPage.loadingMessage')}
        />
      )}
      {deletionState.status === DeletionStatus.success && (
        <DeleteAccountSuccess />
      )}
      {deletionState.status === DeletionStatus.error && (
        <DeleteAccountError
          errorCode={deletionState.errorCode}
          deleteAccount={deleteAccount}
        />
      )}
      <WaitModal
        show={showWaitModal}
        setShowWaitModal={setShowWaitModal}
        ensureCriticalScopes={callEnsureCriticalScopes}
        authToken={authToken}
      />
    </React.Fragment>
  );
};
