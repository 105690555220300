import React, { useEffect, useMemo, useState } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import IconTrash from '@veneer/core/dist/scripts/icons/icon_trash';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import { DeleteAccountTemplate } from '../../components/DeleteAccountTemplate';
import { Text, TextTypes } from '../../components/Text';
import { List, ListItem } from './styles';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import { replaceWithJsx } from '../../utils/utils';
import { Link } from '../../components/Link';
import { SHELL_STATES } from 'src/constants/shellStateParam';
import { UseRootContextType } from '../../types/AppTypes';
import { useTimer } from '../../hooks/useTimer';
import { getLoginCooldown, isCriticalScopesValid } from '../../utils/auth';
import { interfaces } from '@jarvis/shell-commons';
import {
  closeServiceInstance,
  sendJWebFinishEvent
} from '../../utils/jweb/eventService';
import {
  DeleteAccountButtonClicked,
  DeleteAccountDashboardLinkClicked,
  DeleteAccountKeepAccountButtonClicked,
  DeleteAccountScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';

type DeleteAccountNotStartedPropsType = {
  deleteAccount: () => void;
  authToken: typeof interfaces.v1.authToken;
};

export const DeleteAccountHome = ({
  deleteAccount,
  authToken
}: DeleteAccountNotStartedPropsType) => {
  const [isCheckingCriticalScopes, setIsCheckingCriticalScopes] =
    useState(true);
  const [enableEvent, setEnableEvent] = useState(true);
  const { t } = useI18n();
  const { time, startTimer } = useTimer();
  const timerIsEnabled = time > 0;

  const {
    shell: { navigation }
  }: UseRootContextType = useRootContext();

  const goToDashboard = () => {
    publishEvent(DeleteAccountDashboardLinkClicked);
    navigation.push(`/?shellState=${SHELL_STATES.nonGateway}`);
  };

  useEffect(() => {
    const checkCriticalScopes = async () => {
      const loginCooldown = await getLoginCooldown({ authToken });
      const tokenHasCriticalScopes = await isCriticalScopesValid({
        authToken
      });

      if (!tokenHasCriticalScopes) {
        startTimer(loginCooldown);
        setEnableEvent(false);
      }

      setIsCheckingCriticalScopes(false);
    };

    checkCriticalScopes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Buttons = useMemo(
    () => (
      <React.Fragment>
        <Button
          disabled={isCheckingCriticalScopes || timerIsEnabled}
          small={false}
          colorScheme="negative"
          onClick={async () => {
            publishEvent(DeleteAccountButtonClicked);
            deleteAccount();
          }}
          leadingIcon={timerIsEnabled ? <ProgressIndicator /> : <IconTrash />}
          data-testid="delete-account-home-delete-button"
        >
          {timerIsEnabled
            ? time
            : t('myAccount.profile.accountDeletionPage.removeButton')}
        </Button>
        <Button
          small={false}
          appearance="ghost"
          onClick={async () => {
            publishEvent(DeleteAccountKeepAccountButtonClicked);
            await sendJWebFinishEvent();
            await closeServiceInstance();
          }}
        >
          {t('myAccount.profile.accountDeletionPage.keepAccountButton')}
        </Button>
      </React.Fragment>
    ),
    [deleteAccount, isCheckingCriticalScopes, t, time, timerIsEnabled]
  );

  return (
    <DeleteAccountTemplate
      title={{ message: t('myAccount.profile.accountDeletionPage.header') }}
      buttons={Buttons}
      screenDisplayedEvent={() => publishEvent(DeleteAccountScreenDisplayed)}
      enableEvent={enableEvent}
      data-testid="delete-account-home"
    >
      <Text type={TextTypes.paragraph}>
        {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph1')}
      </Text>
      <Text>
        {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph3')}
      </Text>
      <List>
        <ListItem>
          <Text>
            {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph4')}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph5')}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph7')}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph6')}
          </Text>
        </ListItem>
      </List>
      <Text type={TextTypes.paragraph}>
        {replaceWithJsx(
          t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph8'),
          [
            {
              placeholder: '{{link}}',
              jsx: () => (
                <Link
                  onClick={goToDashboard}
                  data-testid="home-here-link"
                >
                  {t(
                    'myAccount.profile.accountDeletionPage.bodyCopy.paragraph8Link'
                  )}
                </Link>
              )
            }
          ]
        )}
      </Text>
      <Text type={TextTypes.paragraph}>
        {t('myAccount.profile.accountDeletionPage.bodyCopy.paragraph9')}
      </Text>
    </DeleteAccountTemplate>
  );
};
