import React, { useCallback } from 'react';
import { Container } from './styles';
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt';
import IconCheckCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle';

type TitlePropsType = {
  message: string;
  iconType?: 'warning' | 'success';
};

export const Title = ({ message, iconType }: TitlePropsType) => {
  const Icon = useCallback(({ type }: { type: string }) => {
    switch (type) {
      case 'success':
        return (
          <IconCheckCircle
            size={30}
            filled
            color="colorGreen6"
            data-testid="title-success-icon"
          />
        );
      case 'warning':
        return (
          <IconWarningAlt
            size={30}
            filled
            color="colorOrange6"
            data-testid="title-warning-icon"
          />
        );
      default:
        return null;
    }
  }, []);

  return (
    <Container>
      {iconType && <Icon type={iconType} />}
      <h1>{message}</h1>
    </Container>
  );
};
