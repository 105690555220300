import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  getPointSelected,
  getPointsAverage,
  getPointsWithoutAverage,
  getTooltipAverage,
} from '../utils';

const SERIES_IDS = {
  SENT: 0,
  RECEIVED: 1,
  AVERAGE_SENT: 2,
  AVERAGE_RECEIVED: 3,
};

const FaxChart = ({
  chartData,
  error,
  loading,
  numMonthsAverage,
  onRetry,
  pointStartAverage,
  selectedYear,
  i18n,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const isDeviceSupportTouch = ('ontouchstart' in window)
                                || (navigator.maxTouchPoints > 0)
                                || (navigator.msMaxTouchPoints > 0);

  const {
    sent,
    received,
    maxAxisY,
  } = chartData;
  const isMultipleFaxUsage = sent && received;

  const colorsDefault = useMemo(() => ((['#00A55C', '#272968', '#672F4F', '#FFAC4D'])), []);
  const colorsHighlighted = useMemo(() => ((['#00D072', '#2D2F86'])), []);

  const widthLegend = useMemo(() => {
    if (!isMultipleFaxUsage) {
      return null;
    }

    const legendText = sent ? i18n.legend.averageSent : i18n.legend.averageReceived;
    return legendText.length < 30 ? '400px' : '450px';
  }, [
    i18n.legend.averageSent,
    i18n.legend.averageReceived,
    isMultipleFaxUsage,
    sent,
  ]);

  const monthMapping = useMemo(() => i18n.abbrMonths
    .reduce((acc, cur, i) => ({
      ...acc,
      [cur]: i18n.months[i],
    }), {}), [i18n.months, i18n.abbrMonths]);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.SENT]: i18n.legend.sent,
    [SERIES_IDS.RECEIVED]: i18n.legend.received,
    [SERIES_IDS.AVERAGE_SENT]: i18n.legend.averageSent,
    [SERIES_IDS.AVERAGE_RECEIVED]: i18n.legend.averageReceived,
  }), [
    i18n.legend.averageReceived,
    i18n.legend.averageSent,
    i18n.legend.received,
    i18n.legend.sent,
  ]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      const chart = this;

      let pointSelected = chart.point;

      if (!pointSelected) {
        pointSelected = getPointSelected(chart.points);
      }

      if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
        return getTooltipAverage(pointSelected);
      }

      const pointsAverage = getPointsAverage(chart?.points);

      let averageTooltip = '';
      if (isDeviceSupportTouch && pointsAverage && pointsAverage.length > 0) {
        const averageSent = pointsAverage.filter(p => p.series.userOptions?.id === SERIES_IDS.SENT)[0];
        const averageReceived = pointsAverage.filter(p => p.series.userOptions?.id === SERIES_IDS.RECEIVED)[0];

        averageTooltip = `<b>${selectedYear}</b>`;
        if (averageSent) {
          averageTooltip += `<br/> ${i18n.legend.sent}: ${averageSent.y} <br/>`;
        }

        if (averageReceived) {
          averageTooltip += `<br/> ${i18n.legend.received}: ${averageReceived.y} <br/>`;
        }
      }

      let total = 0;
      const numPoints = chart.points.length;

      switch (numPoints) {
        case 4:
          total = chart.points[2].y + chart.points[3].y;
          break;
        case 2:
          total = chart.points[1].y;
          break;
        default:
      }

      return averageTooltip + getPointsWithoutAverage(chart.points).reduce((s, point) => `${s}<br/> &nbsp; &nbsp; &#x2022; ${legendTitles[point.series.userOptions.id]}: ${point.y}`,
        `<b>${monthMapping[chart.x]}</b> <br/> ${i18n.total}: ${total}`);
    },
  }), [
    i18n.legend.received,
    i18n.legend.sent,
    i18n.total,
    isDeviceSupportTouch,
    legendTitles,
    monthMapping,
    selectedYear,
    sharedTooltip,
  ]);

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        pointWidth: 10,
        borderRadiusTopLeft: (sent?.average || received?.average) ? 2 : 0,
        borderRadiusTopRight: (sent?.average || received?.average) ? 2 : 0,
      },
      series: {
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    legend: {
      align: 'center',
      width: isMobile ? null : widthLegend,
      itemMarginTop: 8,
      margin: 4,
      itemDistance: isMultipleFaxUsage ? 0 : null,
    },
    yAxis: {
      min: 0,
      max: isMobile ? maxAxisY : null,
      title: {
        text: i18n.yAxis,
      },
    },
    xAxis: {
      min: 0,
      categories: i18n.abbrMonths,
      max: isMobile ? 4 : 11,
      scrollbar: {
        enabled: isMobile,
      },
    },
    series: [
      ...(sent ? [{
        id: SERIES_IDS.AVERAGE_SENT,
        type: 'line',
        name: i18n.legend.averageSent,
        data: Array(numMonthsAverage)
          .fill(null)
          .map((val, i) => (sent.monthly[i] === null ? val : sent.average)),
        color: colorsDefault[2],
        zIndex: 1,
        legendIndex: 1,
        stickyTracking: false,
        isTooltipAverage: true,
        pointStart: pointStartAverage,
        selectedYear,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        pointPlacement: -0.15,
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      }] : []),
      ...(received ? [{
        id: SERIES_IDS.AVERAGE_RECEIVED,
        type: 'line',
        name: i18n.legend.averageReceived,
        data: Array(numMonthsAverage)
          .fill(null)
          .map((val, i) => (received.monthly[i] === null ? val : received.average)),
        color: colorsDefault[3],
        zIndex: 1,
        legendIndex: 3,
        stickyTracking: false,
        isTooltipAverage: true,
        pointStart: pointStartAverage,
        selectedYear,
        pointPlacement: 0.15,
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      }] : []),
      ...(sent ? [{
        id: SERIES_IDS.SENT,
        name: i18n.legend.sent,
        legendIndex: 0,
        data: sent.monthly,
        type: 'column',
        color: colorsDefault[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[0],
            });
          },
        },
      }] : []),
      ...(received ? [{
        id: SERIES_IDS.RECEIVED,
        name: i18n.legend.received,
        legendIndex: 2,
        data: received.monthly,
        type: 'column',
        color: colorsDefault[1],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[1],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[1],
            });
          },
        },
      }] : []),
    ],
  }), [
    colorsDefault,
    colorsHighlighted,
    i18n.abbrMonths,
    i18n.legend.averageReceived,
    i18n.legend.averageSent,
    i18n.legend.received,
    i18n.legend.sent,
    i18n.yAxis,
    isMobile,
    isMultipleFaxUsage,
    maxAxisY,
    numMonthsAverage,
    pointStartAverage,
    received,
    selectedYear,
    sent,
    widthLegend,
  ]);

  return (
    <GenericChart
      data-testid="usage-fax"
      error={error}
      loading={loading}
      onRetry={onRetry}
      tooltip={tooltip}
      options={options}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
      }}
    />
  );
};

FaxChart.defaultProps = {
  pointStartAverage: 0,
  numMonthsAverage: 12,
};

FaxChart.propTypes = {
  chartData: PropTypes.shape({
    sent: PropTypes.shape({
      monthly: PropTypes.arrayOf(PropTypes.number).isRequired,
      average: PropTypes.number.isRequired,
    }),
    received: PropTypes.shape({
      monthly: PropTypes.arrayOf(PropTypes.number).isRequired,
      average: PropTypes.number.isRequired,
    }),
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  selectedYear: PropTypes.number.isRequired,
  pointStartAverage: PropTypes.number,
  numMonthsAverage: PropTypes.number,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      sent: PropTypes.string.isRequired,
      received: PropTypes.string.isRequired,
      averageSent: PropTypes.string.isRequired,
      averageReceived: PropTypes.string.isRequired,
    }).isRequired,
    yAxis: PropTypes.string.isRequired,
    months: PropTypes.arrayOf(PropTypes.string).isRequired,
    abbrMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }).isRequired,
};

export default FaxChart;
