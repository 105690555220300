import React from 'react';
import { Link as CustomLink } from './styles';

type LinkPropsType = {
  children: React.ReactNode;
  [key: string]: unknown;
};

export const Link = ({ children, ...props }: LinkPropsType) => {
  return (
    <CustomLink
      {...props}
      className="label"
    >
      {children}
    </CustomLink>
  );
};
