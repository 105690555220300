type AnalyticsEvent = {
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};
const activity = 'AcctMgmt-v01';
const screenPath = '/ReactConsumerDeleteAccount/';
const eventDetailVersion = '1.4.0';

export const publishEvent = (event: AnalyticsEvent) => {
  const publishCdmEvents = window.Shell.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvent = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.4.0'
  };

  publishCdmEvents([cdmEvent]);
};

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_TOGGLE_DISABLED: 'ControlToggleDisabled',
  CONTROL_TOGGLE_ENABLED: 'ControlToggleEnabled',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed',
  MODULE_DISPLAYED: 'ModuleDisplayed',
  MODULE_INITIALIZED: 'ModuleInitialized',
  SCREEN_DISPLAYED: 'ScreenDisplayed'
};

export const DeleteAccountScreenDisplayed: AnalyticsEvent = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'DeleteAccount',
  version: eventDetailVersion
};

export const DeleteAccountKeepAccountButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'DeleteAccount',
  controlName: 'KeepAccount',
  version: eventDetailVersion
};

export const DeleteAccountButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'DeleteAccount',
  controlName: 'DeleteAccount',
  version: eventDetailVersion
};

export const DeleteAccountDashboardLinkClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName: 'DeleteAccount',
  controlName: 'DashboardLink',
  version: eventDetailVersion
};

// Success

export const AccountDeletedScreenDisplayed: AnalyticsEvent = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'AccountDeleted',
  version: eventDetailVersion
};

export const AccountDeletedDoneButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'AccountDeleted',
  controlName: 'Done',
  version: eventDetailVersion
};

export const AccountDeletedPrivacyLinkClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName: 'AccountDeleted',
  controlName: 'HpPrivacyOffice',
  version: eventDetailVersion
};

// Error - Technical Issue

export const TechnicalIssueScreenDisplayed: AnalyticsEvent = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'UnableToDeleteTechnicalIssue',
  version: eventDetailVersion
};

export const TechnicalIssueHpSupportLinkClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName: 'UnableToDeleteTechnicalIssue',
  controlName: 'HpSupport',
  version: eventDetailVersion
};

export const TechnicalIssueRetryButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'UnableToDeleteTechnicalIssue',
  controlName: 'Retry',
  version: eventDetailVersion
};

export const TechnicalIssueCloseButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'UnableToDeleteTechnicalIssue',
  controlName: 'Close',
  version: eventDetailVersion
};

// Error - Generic

export const UnableToDeleteScreenDisplayed: AnalyticsEvent = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'UnableToDelete',
  version: eventDetailVersion
};

export const UnableToDeleteOpenDashboardButtonClicked: AnalyticsEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'UnableToDelete',
  controlName: 'OpenDashboard',
  version: eventDetailVersion
};
