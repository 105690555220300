import React, { useEffect } from 'react';
import { Title } from '../Title';
import { Container, Content, ButtonGroup } from './styles';

type DeleteAccountTemplatePropsType = {
  title?: {
    message: string;
    iconType?: 'warning' | 'success';
  };
  children: React.ReactNode;
  buttons?: React.ReactNode;
  screenDisplayedEvent?: () => void;
  enableEvent?: boolean;
  [key: string]: unknown;
};

export const DeleteAccountTemplate = ({
  title,
  children,
  buttons,
  screenDisplayedEvent,
  enableEvent = true,
  ...props
}: DeleteAccountTemplatePropsType) => {
  useEffect(() => {
    if (enableEvent && screenDisplayedEvent) {
      screenDisplayedEvent();
    }
  }, [enableEvent, screenDisplayedEvent]);

  return (
    <Container {...props}>
      {title && (
        <Title
          message={title.message}
          iconType={title.iconType}
        />
      )}
      <Content>
        {children}
        {buttons && (
          <ButtonGroup data-testid="delete-account-template-button-group">
            {buttons}
          </ButtonGroup>
        )}
      </Content>
    </Container>
  );
};
