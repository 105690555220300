import styled from 'styled-components';

export const GenericText = styled.p`
  && {
    line-height: 24px;
  }
`;

export const Paragraph = styled(GenericText)`
  && {
    margin-bottom: 18px;
  }
`;
