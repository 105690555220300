import styled from 'styled-components';
import Modal from '@veneer/core/dist/scripts/modal';

export const ContentContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const CustomModal = styled(Modal)`
  .vn-modal--dialog {
    width: 100%;
    .vn-modal--content {
      width: calc(100% - 48px);
      max-width: 640px;
    }
  }

  @media (max-width: 767px) {
    && {
      .vn-modal--dialog {
        width: 100%;
        .vn-modal--content {
          width: calc(100% - 48px);
          word-wrap: break-word;
        }
        .vn-modal--body {
          width: 100%;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    && {
      .vn-modal--dialog {
        width: 100%;
        .vn-modal--content {
          padding: 24px;
        }
      }
    }
  }
`;

export const Message = styled.p`
  text-align: center;
`;

export const Timer = styled(Message)`
  margin-left: 10px;
`;
