export const HTTP_STATUS_CODES = {
  OK: 200,
  NO_CONTENT: 204,
  FORBIDDEN: 403,
  UNAUTHORIZED: 401
};

export const SUCCESS_HTTP_CODES = [
  HTTP_STATUS_CODES.OK,
  HTTP_STATUS_CODES.NO_CONTENT
];
