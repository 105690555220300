import React from 'react';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import { DeleteAccountTemplate } from '../../components/DeleteAccountTemplate';
import { Text } from '../../components/Text';
import { Container } from './styles';

type DeleteAccountLoadingPropTypes = {
  message?: string;
};

export const DeleteAccountLoading = ({
  message
}: DeleteAccountLoadingPropTypes) => {
  return (
    <DeleteAccountTemplate data-testid="delete-account-loading">
      <Container fullScreen={!message}>
        <ProgressIndicator />
        <Text>{message}</Text>
      </Container>
    </DeleteAccountTemplate>
  );
};
