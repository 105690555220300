import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tokens from '@veneer/tokens';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  getPointSelected,
  getPointsAverage,
  getPointsWithoutAverage,
  getTooltipAverage,
  getTooltipGeneralAverage,
} from '../utils';

const SERIES_IDS = {
  AVERAGE: 0,
  BLUEPRINT: 1,
  MONO_LINES: 2,
  COLOR_LINES: 3,
  LOW_DENSITY: 4,
  HIGH_DENSITY: 5,
  PREMIUM: 6,
};

const PrintAreaChart = ({
  chartData,
  error,
  isSquareFeet,
  loading,
  onRetry,
  selectedYear,
  pointStartAverage,
  i18n,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const colorsDefault = useMemo(() => ((
    ['#272968', '#733E73', '#E54577', '#FF6445', '#FFAC4D', '#FFE137', '#4483CE']
  )), []);
  const colorsHighlighted = useMemo(() => ((
    ['#2D2F86', '#964E96', '#FA6996', '#FF8373', '#FFC480', '#FFED4D']
  )), []);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.BLUEPRINT]: i18n.legend.blueprint,
    [SERIES_IDS.MONO_LINES]: i18n.legend.monoLines,
    [SERIES_IDS.COLOR_LINES]: i18n.legend.colorLines,
    [SERIES_IDS.LOW_DENSITY]: i18n.legend.lowDensity,
    [SERIES_IDS.HIGH_DENSITY]: i18n.legend.highDensity,
    [SERIES_IDS.PREMIUM]: i18n.legend.premium,
    [SERIES_IDS.AVERAGE]: i18n.legend.average,
  }), [
    i18n.legend.average,
    i18n.legend.blueprint,
    i18n.legend.colorLines,
    i18n.legend.highDensity,
    i18n.legend.lowDensity,
    i18n.legend.monoLines,
    i18n.legend.premium,
  ]);

  const monthMapping = useMemo(() => i18n.abbrMonths
    .reduce((acc, cur, i) => ({
      ...acc,
      [cur]: i18n.months[i],
    }), {}), [i18n.months, i18n.abbrMonths]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      const chart = this;
      let pointSelected = chart.point;

      if (!pointSelected) {
        pointSelected = getPointSelected(chart.points);
      }

      if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
        return getTooltipAverage(pointSelected);
      }

      const pointsAverage = getPointsAverage(chart?.points);

      return getTooltipGeneralAverage(pointsAverage) + getPointsWithoutAverage(chart.points).reduce((s, point) => `${s}<br/> &nbsp; &nbsp; &#x2022; ${legendTitles[point.series.userOptions.id]}: ${point.y}`,
        `<b>${monthMapping[chart.x]}</b> <br/> ${i18n.total}: ${chart.points[1].total.toFixed(2)}`);
    },
  }), [
    i18n.total,
    legendTitles,
    monthMapping,
    sharedTooltip,
  ]);

  const { categories, maxAxisY } = chartData;

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        pointWidth: 16,
      },
      series: {
        stacking: 'normal',
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    yAxis: {
      min: 0,
      max: isMobile ? maxAxisY : null,
      title: {
        text: isSquareFeet ? i18n.unit.sqft
          : i18n.unit.sqm,
      },
    },
    xAxis: {
      min: 0,
      max: isMobile ? 4 : 11,
      scrollbar: {
        enabled: isMobile,
      },
      categories: i18n.abbrMonths,
    },
    legend: {
      reversed: true,
      itemMarginTop: 8,
      margin: 4,
    },
    series: [
      {
        id: SERIES_IDS.AVERAGE,
        type: 'line',
        isTooltipAverage: true,
        selectedYear,
        name: i18n.legend.average,
        data: categories.blueprint.slice(pointStartAverage).map(val => (val === null ? val : chartData.average)),
        color: colorsDefault[6],
        zIndex: 1,
        stickyTracking: false,
        pointStart: pointStartAverage,
        events: {
          mouseOver() {
            setSharedTooltip(false);
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[6],
            });
          },
          mouseOut() {
            setSharedTooltip(true);
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[6],
            });
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      {
        id: SERIES_IDS.PREMIUM,
        borderRadiusTopLeft: chartData.average ? tokens.borderRadius2 : 0,
        borderRadiusTopRight: chartData.average ? tokens.borderRadius2 : 0,
        name: i18n.legend.premium,
        data: categories.premium,
        color: colorsDefault[5],
        type: 'column',
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[5],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[5],
            });
          },
        },
      },
      {
        id: SERIES_IDS.HIGH_DENSITY,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.highDensity,
        data: categories.highDensity,
        type: 'column',
        color: colorsDefault[4],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[4],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[4],
            });
          },
        },
      },
      {
        id: SERIES_IDS.LOW_DENSITY,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.lowDensity,
        data: categories.lowDensity,
        type: 'column',
        color: colorsDefault[3],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[3],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[3],
            });
          },
        },
      },
      {
        id: SERIES_IDS.COLOR_LINES,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.colorLines,
        data: categories.colorLines,
        type: 'column',
        color: colorsDefault[2],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[2],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[2],
            });
          },
        },
      },
      {
        id: SERIES_IDS.MONO_LINES,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.monoLines,
        data: categories.monoLines,
        type: 'column',
        color: colorsDefault[1],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[1],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[1],
            });
          },
        },
      },
      {
        id: SERIES_IDS.BLUEPRINT,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.blueprint,
        data: categories.blueprint,
        type: 'column',
        color: colorsDefault[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[0],
            });
          },
        },
      },
    ],
  }), [
    chartData.average,
    categories.blueprint,
    categories.colorLines,
    categories.highDensity,
    categories.lowDensity,
    categories.monoLines,
    categories.premium,
    isSquareFeet,
    isMobile,
    colorsDefault,
    colorsHighlighted,
    maxAxisY,
    pointStartAverage,
    selectedYear,
    i18n.abbrMonths,
    i18n.legend.average,
    i18n.legend.blueprint,
    i18n.legend.colorLines,
    i18n.legend.highDensity,
    i18n.legend.lowDensity,
    i18n.legend.monoLines,
    i18n.legend.premium,
    i18n.unit.sqft,
    i18n.unit.sqm,
  ]);

  return (
    <GenericChart
      data-testid="usage-print-area"
      error={error}
      loading={loading}
      onRetry={onRetry}
      options={options}
      tooltip={tooltip}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
      }}
    />
  );
};

PrintAreaChart.defaultProps = {
  pointStartAverage: 0,
};

PrintAreaChart.propTypes = {
  chartData: PropTypes.shape({
    categories: PropTypes.shape({
      blueprint: PropTypes.arrayOf(PropTypes.number).isRequired,
      monoLines: PropTypes.arrayOf(PropTypes.number).isRequired,
      colorLines: PropTypes.arrayOf(PropTypes.number).isRequired,
      lowDensity: PropTypes.arrayOf(PropTypes.number).isRequired,
      highDensity: PropTypes.arrayOf(PropTypes.number).isRequired,
      premium: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    average: PropTypes.number.isRequired,
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  isSquareFeet: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  selectedYear: PropTypes.number.isRequired,
  pointStartAverage: PropTypes.number,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      average: PropTypes.string.isRequired,
      blueprint: PropTypes.string.isRequired,
      monoLines: PropTypes.string.isRequired,
      colorLines: PropTypes.string.isRequired,
      lowDensity: PropTypes.string.isRequired,
      highDensity: PropTypes.string.isRequired,
      premium: PropTypes.string.isRequired,
    }).isRequired,
    months: PropTypes.arrayOf(PropTypes.string).isRequired,
    abbrMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
    unit: PropTypes.shape({
      sqft: PropTypes.string.isRequired,
      sqm: PropTypes.string.isRequired,
    }).isRequired,
    total: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrintAreaChart;
