import {
  jWebReady,
  CreatePublisherOptions,
  EventServicePluginError,
  EventServicePlugin,
  Publisher,
  Subscriber
} from '@jarvis/jweb-core';
import { JWEB_EVENTS } from '../../constants/jweb';

let EventService: EventServicePlugin;

async function ensureJWeb() {
  jWebReady
    .then((jweb) => {
      if (jweb && jweb.isPluginAvailable('EventService')) {
        EventService = jweb.Plugins.EventService;
      } else {
        console.error(
          'jWebReady: unable to obtain EventServicePlugin (missing JWeb objects)'
        );
      }
    })
    .catch((error) => {
      console.error(
        `jWebReady: unable to obtain EventServicePlugin (error: ${error})`
      );
    });
}

const hasEventServicePluginError = (
  subscriber: Subscriber | EventServicePluginError
) => {
  const hasError =
    (subscriber as EventServicePluginError).errorType !== undefined;
  if (hasError) {
    console.error(
      'has event service plugin while dispatch event error',
      (subscriber as EventServicePluginError).errorType
    );
  }
  return hasError;
};

const generateRandomValue = () => {
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  return (array[0].toString(36) + array[1].toString(36)).substring(0, 10);
};

const getPublisher = async () => {
  const publisherCryptoValue = generateRandomValue();

  const publisherId = `com.hp.shortcuts.dispatchevent.publisher-${publisherCryptoValue}`;
  const options: CreatePublisherOptions = {
    allowEventingFallback: true
  };

  const publisher = await EventService.createPublisher(publisherId, options);

  if (hasEventServicePluginError(publisher as EventServicePluginError))
    console.error(
      'get publisher in dispatch event failed',
      (publisher as EventServicePluginError).errorType
    );
  else return publisher as Publisher;
};

export const closeServiceInstance = async () => {
  await ensureJWeb();
  try {
    const publisher = await getPublisher();
    await publisher.publish(JWEB_EVENTS.CLOSE, {});
  } catch (err) {
    console.error(`Error closing service instance:\n${err}`);
  }
};

export const sendJWebFinishEvent = async () => {
  await ensureJWeb();
  try {
    const publisher = await getPublisher();
    await publisher.publish(JWEB_EVENTS.JARVIS_FINISHED, {});
  } catch (err) {
    console.error(`Error sending jweb finish event:\n${err}`);
  }
};

export const sendJWebCleanupAccountDeletion = async () => {
  await ensureJWeb();
  try {
    const publisher = await getPublisher();
    await publisher.publish(JWEB_EVENTS.CLEANUP_ACCOUNT_DELETION, {});
  } catch (err) {
    console.error(`Error sending jweb finish event:\n${err}`);
  }
};
