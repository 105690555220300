import React from 'react';
import PropTypes from 'prop-types';
import {
  Content,
  Separator,
} from './styles';

const Card = ({
  children,
  className,
  dataTestId,
  header,
}) => (
  <div
    data-testid={dataTestId}
    className={className}
  >
    {header && (
      <>
        {header}
        {children && <Separator />}
      </>
    )}

    {children && (
      <Content className="card-content">
        {children}
      </Content>
    )}
  </div>
);

Card.defaultProps = {
  className: null,
  dataTestId: null,
  header: undefined,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  header: PropTypes.node,
};

export default Card;
