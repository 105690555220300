import styled from 'styled-components';

type ContainerPropsType = {
  fullScreen?: boolean;
};

export const Container = styled.div<ContainerPropsType>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ fullScreen }) =>
    fullScreen &&
    `
    height: calc(100vh - 112px);
    align-items: center;
    justify-content: center;
  `}

  div {
    margin-bottom: 8px;
  }
`;
