import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    font-size: 32px;
    line-height: 36px;
  }

  svg {
    align-self: center;
    margin-right: 12px;
    min-width: 30px;
    min-height: 30px;
  }

  @media (max-width: ${tokens.smMin}) {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;
