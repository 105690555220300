import { jWebReady, AuthPlugin } from '@jarvis/jweb-core';

let Auth: AuthPlugin;

export const isJWebNative = () => window.JWeb?.isNative;

async function ensureJWeb() {
  jWebReady
    .then((jweb) => {
      if (jweb && jweb.isPluginAvailable('Auth')) {
        Auth = jweb.Plugins.Auth;
      } else {
        console.error(
          'jWebReady: unable to obtain AuthPlugin (missing JWeb objects)'
        );
      }
    })
    .catch((error) => {
      console.error(`jWebReady: unable to obtain AuthPlugin (error: ${error})`);
    });
}
export const triggerLogout = async () => {
  try {
    await ensureJWeb();
    await Auth.logout();
  } catch (err) {
    console.error(`Error logging out:\n${err}`);
  }
};
