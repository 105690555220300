import React, { memo, useEffect, useState } from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import { getLoginCooldown } from '../../utils/auth';
import { interfaces } from '@jarvis/shell-commons';
import { useTimer } from '../../hooks/useTimer';
import {
  ContentContainer,
  CustomModal,
  Message,
  ProgressContainer,
  Timer
} from './styles';

type WaitModalProps = {
  show: boolean;
  setShowWaitModal: (show: boolean) => void;
  ensureCriticalScopes: () => void;
  authToken: typeof interfaces.v1.authToken;
};

export const WaitModal = memo(
  ({
    show,
    setShowWaitModal,
    ensureCriticalScopes,
    authToken
  }: WaitModalProps) => {
    const { t } = useI18n();
    const [timerIsEnabled, setTimerIsEnabled] = useState(false);
    const { time, startTimer } = useTimer();

    useEffect(() => {
      (async function setLoginCooldown() {
        if (show) {
          const cooldown = await getLoginCooldown({ authToken });
          startTimer(cooldown);
          setTimerIsEnabled(true);
          setShowWaitModal(false);
        }
      })();
    }, [authToken, setShowWaitModal, show, startTimer]);

    useEffect(() => {
      (async function deleteAccount() {
        if (time === 0 && !show && timerIsEnabled) {
          setTimerIsEnabled(false);
          await ensureCriticalScopes();
        }
      })();
    }, [time, show, timerIsEnabled, ensureCriticalScopes]);

    return (
      <CustomModal
        data-testid="delete-account-wait-modal-container"
        show={time > 0}
      >
        <ContentContainer data-testid="delete-account-wait-modal-content">
          <ProgressContainer>
            <ProgressIndicator data-testid="delete-account-wait-modal-progress-indicator" />
            <Timer data-testid="delete-account-wait-modal-timer">{time}</Timer>
          </ProgressContainer>
          <Message data-testid="delete-account-wait-modal-message">
            {t('myPrinters.transferOwnershipModal.processingTranfer.header')}
          </Message>
        </ContentContainer>
      </CustomModal>
    );
  }
);

WaitModal.displayName = 'WaitModal';
