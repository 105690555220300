import { TelemetryClient } from '@jarvis/web-stratus-client';

export default async function getJobsScanCounters({
  authProvider,
  deviceId,
  endDate,
  numDays,
  stack,
  startDate,
}) {
  const client = new TelemetryClient(stack, authProvider);
  const response = await client.getScanCountersByTenancy(undefined, deviceId, startDate, endDate, numDays);

  return response;
}
