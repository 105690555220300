const ONE_TAG_MATCHER = '(%placeholder%)';
const TWO_TAG_MATCHER = '%start%(.*)%end%';
/**
 * Replaces the placeholders within text with the jsx provided in the matches array.
 * @param {string} text: text to be parsed, containing placeholders to be replaced
 * example 1: 'Hello {{tagReplaceStart}}world{{tagReplaceEnd}}!'
 * example 2: 'Hello {{tagReplace}}!'
 * example 3: '{{tagReplace}} {{tagReplaceStart}}world{{tagReplaceEnd}}!'
 * @param {array} matches: array of objects with the following structure:
 * {
 *   start: string,
 *   end: string,
 *   placeholder: string,
 *   jsx: jsx element or function that returns jsx element or string
 * }
 * example: [
 * {
 *   start: '{{tagReplaceStart}}',
 *   end: '{{tagReplaceEnd}}',
 *   jsx: (text) => <span>{text}</span>
 * },
 * {
 *   placeholder: '{{tagReplace}}',
 *   jsx: 'Hello'
 * }
 * @returns {array} array of strings and jsx elements
 */

export const replaceWithJsx = (text, matches) => {
  const m = {};

  const regExp = matches
    .map((match) => {
      const { start, end, placeholder } = match;
      if (start && end) {
        m[start] = match;
        return TWO_TAG_MATCHER.replace('%start%', start).replace('%end%', end);
      }
      m[placeholder] = match;
      return ONE_TAG_MATCHER.replace('%placeholder%', placeholder);
    })
    .join('|');

  const tagMatcher = new RegExp(regExp, 'g');

  return text
    .split(tagMatcher)
    .map((value, index) => {
      const match = index % (matches.length + 1);
      let jsx = undefined;
      if (match && value) {
        jsx = matches[match - 1].jsx;
      }
      if (jsx) {
        if (typeof jsx === 'function') {
          return jsx(value);
        }
        return jsx;
      }
      return value;
    })
    .filter((content) => content);
};

export const getDateDifferenceInSeconds = (firstDate, secondDate) => {
  const differenceInMilliseconds = firstDate - secondDate;
  return Math.floor(differenceInMilliseconds / 1000);
};
