import React, { useMemo } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import { DeleteAccountTemplate } from '../../components/DeleteAccountTemplate';
import { Text, TextTypes } from '../../components/Text';
import { useI18n } from '@jarvis/react-portal-addons';
import { replaceWithJsx } from '../../utils/utils';
import { Link } from '../../components/Link';
import {
  closeServiceInstance,
  sendJWebCleanupAccountDeletion,
  sendJWebFinishEvent
} from '../../utils/jweb/eventService';
import { triggerLogout } from '../../utils/jweb/auth';
import {
  AccountDeletedDoneButtonClicked,
  AccountDeletedPrivacyLinkClicked,
  AccountDeletedScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';

export const DeleteAccountSuccess = () => {
  const { t } = useI18n();

  const Buttons = useMemo(
    () => (
      <React.Fragment>
        <Button
          data-testid="success-done-button"
          onClick={async () => {
            publishEvent(AccountDeletedDoneButtonClicked);
            await sendJWebCleanupAccountDeletion();
            await sendJWebFinishEvent();
            await closeServiceInstance();
            await triggerLogout();
          }}
        >
          {t('myAccount.profile.accountDeletionPage.success.doneButton')}
        </Button>
      </React.Fragment>
    ),
    [t]
  );

  return (
    <DeleteAccountTemplate
      title={{
        message: t('myAccount.profile.accountDeletionPage.success.header'),
        iconType: 'success'
      }}
      buttons={Buttons}
      screenDisplayedEvent={() => publishEvent(AccountDeletedScreenDisplayed)}
      data-testid="delete-account-success"
    >
      <Text type={TextTypes.paragraph}>
        {t('myAccount.profile.accountDeletionPage.success.paragraph1')}
      </Text>
      <Text type={TextTypes.paragraph}>
        {replaceWithJsx(
          t('myAccount.profile.accountDeletionPage.success.paragraph2'),
          [
            {
              placeholder: '{{link}}',
              jsx: () => (
                <Link
                  href="https://www.hp.com/us-en/privacy/privacy-feedback.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => publishEvent(AccountDeletedPrivacyLinkClicked)}
                >
                  {t(
                    'myAccount.profile.accountDeletionPage.success.paragraph2Link'
                  )}
                </Link>
              )
            }
          ]
        )}
      </Text>
    </DeleteAccountTemplate>
  );
};
