import { TelemetryClient } from '@jarvis/web-stratus-client';

export default async function getJobsPrintAreaCounters({
  authProvider,
  deviceId,
  endDate,
  stack,
  startDate,
}) {
  const client = new TelemetryClient(stack, authProvider);
  const response = await client.getAreaUsageByPrintCategoryCountersByTenancy(undefined, deviceId, startDate, endDate);

  return response;
}
