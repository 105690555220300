import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `${theme.orbitalThree} ${theme.orbitalSix}`};
`;

export const Title = styled.p`
  flex: fit-content;
`;

export const LeftArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
