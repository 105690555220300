import styled from 'styled-components';

export const Link = styled.a`
  font-weight: bold;

  &:link {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
