import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  padding: 80px 64px 32px 64px;
  max-width: 646px;

  @media (max-width: ${tokens.smMin}) {
    padding: 40px 32px;
  }
`;

export const Content = styled.div``;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 56px;
  flex-wrap: wrap;

  button:nth-child(1) {
    margin-right: 10px;
  }

  @media (max-width: ${tokens.smMin}) {
    flex-direction: column;

    button:nth-child(1) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;
